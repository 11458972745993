import React, {useState, useEffect} from "react";
import {Button, Col, Container, Row, Form, Modal} from "react-bootstrap"
import {ValidateInputEmail, validateInputNumeric, validateInputPhoneNumber} from "../../helpers/validates";
import {apiRequest} from "../../apiRquest/apiRequest";
import Turnstile from "react-turnstile";
import './propuesta.css';


const initialValues = {
    persona_fisica: true,
    nombre: '',
    apellido: '',
    correo: '',
    dni: '',
    codigo_pais: '',
    codigo_area: '',
    telefono: '',
    idoneo: false,
    matricula_cnv: false,
    examen_idoneidad: false,
    idCampaign: 834
}

const initialValuesErrorGeneral = {
    nombre: '',
    apellido: '',
    correo: '',
    dni: '',
    codigo_pais: '',
    codigo_area: '',
    telefono: '',
}

const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


/**
 * Formulario para una propuesta
 *
 * @returns
 */
const Propuesta = () => {
    // Datos del formulario
    const [data, setData] = React.useState(initialValues);
    const [errorMessage, setErrorMessage] = React.useState(initialValuesErrorGeneral);
    const [statusMsg, setStatusMsg] = useState("");
    const [successResponse, setSuccessResponse] = useState("");
    const [modalShow, setModalShow] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [ref, setRef] = useState('-');
    const [token, setToken] = useState();
    const [error, setError] = useState(false);



    /**
     * Detecta cambios en cada campo del fomulario
     *
     * @param {*} event
     */
    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
    
        if (['checkbox', 'radio'].includes(type)) {
            if (name === "matricula_cnv" && checked) {
                setData({
                    ...data,
                    matricula_cnv: true,
                    examen_idoneidad: false,
                });
            } else if (name === "examen_idoneidad" && checked) {
                setData({
                    ...data,
                    matricula_cnv: false,
                    examen_idoneidad: true,
                });
            } else {
                setData({
                    ...data,
                    [name]: checked,
                });
            }
        } else {
            setData({
                ...data,
                [name]: value,
            });
        }
    }

    useEffect(() => {
        let url_params = new URLSearchParams(window.location.search);
        let param = url_params.get('bam_campaign');
        param = parseInt(param);
        if(param && param > 0){
            setData((old) => ({
                ...old,
                idCampaign: param
            }))
        }

        let ref = url_params.get('ref')
        if(ref) {
            setRef(ref)
        }
    }, []);
    const validateFormData = (data) => {
        let errors = {
            nombre: '',
            apellido: '',
            correo: '',
            dni: '',
            codigo_pais: '',
            codigo_area: '',
            telefono: '',
        };
        let success = true;

        if (data.nombre.length === 0) {
            errors.nombre = "campo requerido";
            success = false;
        } else if (data.nombre.length < 3) {
            errors.nombre = "campo nombre debe contener al menos 3 caracteres";
            success = false;
        }

        if (data.apellido.length === 0) {
            errors.apellido = "campo requerido";
            success = false;
        } else if (data.apellido.length < 3) {
            errors.apellido = "campo apellido debe contener al menos 3 caracteres";
            success = false;
        }

        if (data.correo.length === 0) {
            errors.correo = "campo requerido";
            success = false;
        } else if (!ValidateInputEmail(data.correo)) {
            errors.correo = "email inválido";
            success = false;
        }

        if (data.dni.length === 0) {
            errors.dni = "campo requerido";
            success = false;
        } else if (!validateInputNumeric(data.dni)) {
            errors.dni = "campo dni debe ser numérico (0-9)";
            success = false;
        } else if (data.dni.length < 5) {
            errors.dni = "campo dni debe contener al menos 5 caracteres";
            success = false;
        }

        if (data.codigo_pais.length === 0) {
            errors.codigo_pais = "campo requerido";
            success = false;
        } else if (!validateInputNumeric(data.codigo_pais)) {
            errors.codigo_pais = "campo codigo país debe ser numérico (0-9)";
            success = false;
        }

        if (data.codigo_area.length === 0) {
            errors.codigo_area = "campo requerido";
            success = false;
        } else if (!validateInputNumeric(data.codigo_area)) {
            errors.codigo_area = "campo codigo área debe ser numérico (0-9)";
            success = false;
        }

        if (data.telefono.length === 0) {
            errors.telefono = "campo requerido";
            success = false;
        } else if (!validateInputNumeric(data.telefono)) {
            errors.telefono = "campo teléfono debe ser numérico (0-9)";
            success = false;
        } else if (data.telefono.length < 5) {
            errors.telefono = "campo telefono debe contener al menos 5 caracteres";
            success = false;
        }

        return { errors, success };
    };


    const send_data = () => {
        setErrorMessage(initialValuesErrorGeneral);
        const { errors, success } = validateFormData(data);
        setErrorMessage(errors);
        
        data.recaptchaToken = token; 
        if (success) {
            setLoading(true) 
            data.ref = ref;
            apiRequest.post("v1/contacts/send_ifa_prospect", data)
                .then((res) => {
                    setStatusMsg("Datos enviados correctamente");
                    setModalShow(true);
                    setSuccessResponse(true);
                    setData(initialValues);
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event': 'formulario_exitoso'
                    });
                    setLoading(false)
                    console.log(res)
                })
                .catch((error) => { 
                    let errorMsg = "Hubo un problema en el guardado de los datos, pruebe nuevamente en unos instantes";
                    if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
                        errorMsg = error.response.data.errors[0];
                    } else if (error.response && error.response.data && error.response.data.error) {
                        errorMsg = error.response.data.error;
                    } else if (error.response && error.response.data && error.response.data.message) {
                        errorMsg = error.response.data.message;
                    }
                setStatusMsg(errorMsg);
                    setModalShow(true);
                    setSuccessResponse(false);
                    setData(initialValues);
                    setLoading(false)
                });
        }
    }


    return (
        <>
            <div className="bg-lt-grey py-5">
                <Container>
                    <h2 className="text-center">Tenemos una propuesta para vos.</h2>
                    <h3 className="text-center"><span className="primary-color">Completá tus datos</span></h3>

                    {/*<div className='text-center'>
                    <div className="my-3 propuesta-form-checkbox">
                        <Form.Check
                            type="radio"
                            checked={data.persona_fisica}
                            onChange={handleChange}
                            name="persona_fisica"
                            label="Persona Física"
                        />
                    </div>
                </div>*/}

                    <Row className="suscribe-talks">
                        <Col xs={12} md={6} className="">
                            <input
                                className={`py-1 mb-1 mt-4 bg-transparent`}
                                type="text"
                                value={data.nombre}
                                name="nombre"
                                placeholder="Nombre"
                                onChange={handleChange}
                                style={{color: "black"}}
                            />
                            <span style={{color:"red", fontSize:'80%'}}>{errorMessage.nombre}</span>
                        </Col>

                        <Col xs={12} md={6} className="">
                            <input
                                className={`py-1 mb-1 mt-4 bg-transparent`}
                                type="text"
                                value={data.apellido}
                                name="apellido"
                                placeholder="Apellido"
                                onChange={handleChange}
                                style={{color: "black"}}
                            />
                            <span style={{color:"red", fontSize:'80%'}}>{errorMessage.apellido}</span>
                        </Col>

                        <Col xs={12} md={6} className="">
                            <input
                                className={`py-1 mb-1 mt-4 bg-transparent`}
                                type="email"
                                value={data.correo}
                                name="correo"
                                placeholder="Correo"
                                onChange={handleChange}
                                style={{color: "black"}}
                            />
                            <span style={{color:"red", fontSize:'80%'}}>{errorMessage.correo}</span>
                        </Col>

                        <Col xs={12} md={6} className="">
                            <input
                                className={`py-1 mb-1 mt-4 bg-transparent`}
                                type="text"
                                value={data.dni}
                                name="dni"
                                placeholder="DNI"
                                onChange={handleChange}
                                style={{color: "black"}}
                            />
                            <span style={{color:"red", fontSize:'80%'}}>{errorMessage.dni}</span>
                        </Col>

                        <Col xs={12} md={6} className="">
                            <Row>
                                <Col xs={12} md={4}>
                                    <div className="d-flex align-items-end">
                                        <b style={{
                                            paddingRight: '0.75rem',
                                            fontSize: '2rem',
                                            lineHeight: 'unset'
                                        }}>+</b>
                                        <input
                                            className={`py-1 mb-1 mt-4 bg-transparent`}
                                            type="tel"
                                            value={data.codigo_pais}
                                            name="codigo_pais"
                                            placeholder="54"
                                            onChange={handleChange}
                                            style={{color: "black"}}
                                        />
                                    </div>
                                    <span style={{color:"red", fontSize:'80%'}}>{errorMessage.codigo_pais}</span>
                                </Col>
                                <Col xs={12} md={4}>
                                    <input
                                        className={`py-1 mb-1 mt-4 bg-transparent`}
                                        type="tel"
                                        value={data.codigo_area}
                                        name="codigo_area"
                                        placeholder="Área"
                                        onChange={handleChange}
                                        style={{color: "black"}}
                                    />
                                    <span style={{color:"red", fontSize:'80%'}}>{errorMessage.codigo_area}</span>
                                </Col>
                                <Col xs={12} md={4}>
                                    <input
                                        className={`py-1 mb-1 mt-4 bg-transparent`}
                                        type="tel"
                                        value={data.telefono}
                                        name="telefono"
                                        placeholder="Teléfono"
                                        onChange={handleChange}
                                        style={{color: "black"}}
                                    />
                                    <span style={{color:"red", fontSize:'80%'}}>{errorMessage.telefono}</span>
                                </Col>
                            </Row>
                        </Col>
                    </Row>


                    <div className='text-center'>
                        <div className="my-3 propuesta-form-checkbox checkbox-start">
                            <Form.Check
                                type="checkbox"
                                name="matricula_cnv"
                                checked={data.matricula_cnv}
                                onChange={handleChange}
                                label="Tengo matricula CNV"
                            />
                            <Form.Check
                                className="from-propuesta-checkbox"
                                type="checkbox"
                                name="examen_idoneidad"
                                checked={data.examen_idoneidad}
                                onChange={handleChange}
                                label="Rendí el examen de Idoneidad, pero aún no me he matriculado"
                            />
                        </div>
                    </div>
                    <div className="text-center">
                        {!loading ? (
                            <>
                            <Turnstile 
                                sitekey={process.env.CLOUDFLARE_SITEKEY}
                                theme="light"
                                action="send_ifa_prospect"
                                onVerify={(token) => setToken(token)}
                            />
                            <Button onClick={send_data} variant="secondary">Quiero postularme</Button>
                            </>
                        ) : (
                            <Button disabled onClick={() => false} variant="secondary">Quiero postularme</Button>
                        )}

                    </div>

                </Container>
            </div>
            <Modal className="formmodal" show={modalShow} onHide={() => setModalShow(false)}
                   size="lg"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{successResponse ? 'Muchas gracias' : 'Atención'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div dangerouslySetInnerHTML={{__html: statusMsg}}/>
                </Modal.Body>
            </Modal>
        </>
    )
}


export default Propuesta;
